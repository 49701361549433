import Dynamic from "next/dynamic";
import { getHomePageContentData } from "../services/user";

const HomePage = Dynamic(import("../components/Home/HomePage"));


export default function Home() {
  return (
    <>
      <HomePage />
    </>
  );
}

export async function getServerSideProps() {
  return {
    props: {},
  };
}
